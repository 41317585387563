<script setup lang="ts">
import { ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'

const route = useRoute()

const shouldRedirect = ['index', 'home'].includes(route.name)

const searchQuery = ref((route.query.query as string) || '')

const clearSearch = () => {
  searchQuery.value = ''
}

const debouncedSearch = useDebounceFn(async (query: string) => {
  if (query) {
    await navigateTo({
      path: shouldRedirect ? '/explore/' : route.path,
      replace: true,
      query: {
        ...route.query,
        query,
      },
    })
  } else {
    await navigateTo({
      path: shouldRedirect ? '/explore/' : route.path,
      replace: true,
    })
  }
}, 500)

watch(
  () => route.query.query,
  (value) => {
    if (!searchQuery.value.length && value) {
      searchQuery.value = value as string
    }
  }
)

watch(searchQuery, (newQuery) => {
  debouncedSearch(newQuery)
})
</script>

<template>
  <div class="grid grid-cols-1 gap-8">
    <div class="relative w-full">
      <div class="absolute inset-y-0 start-0 flex items-center pl-5 pointer-events-none">
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.5 6.61719C0.5 3.04688 3.39062 0.15625 6.95312 0.15625C10.5156 0.15625 13.4062 3.04688 13.4062 6.61719C13.4062 7.94531 13 9.17188 12.2969 10.1797L15.6641 13.5625C15.9141 13.8125 16.0469 14.1484 16.0469 14.5078C16.0469 15.2578 15.4844 15.8438 14.7188 15.8438C14.3594 15.8438 14.0156 15.7188 13.7578 15.4609L10.3594 12.0625C9.38281 12.6953 8.21875 13.0703 6.95312 13.0703C3.39062 13.0703 0.5 10.1797 0.5 6.61719ZM2.40625 6.61719C2.40625 9.125 4.4375 11.1641 6.95312 11.1641C9.46875 11.1641 11.5078 9.125 11.5078 6.61719C11.5078 4.10156 9.46875 2.0625 6.95312 2.0625C4.4375 2.0625 2.40625 4.10156 2.40625 6.61719Z"
            fill="#999999"
          />
        </svg>
      </div>
      <input
        v-model="searchQuery"
        type="text"
        class="bg-zeno-gray-light border-none md:text-lg sm:text-base text-sm rounded-full w-full ps-12 pe-6 p-4 placeholder:text-zeno-gray"
        :placeholder="
          $viewport.isLessThan('md')
            ? 'Find genres, stations or podcasts'
            : 'Search for genres, stations, podcasts or countries'
        "
        :width="$viewport.isLessThan('md') ? 80 : 128"
      />
      <button v-if="searchQuery" class="absolute inset-y-0 end-0 flex items-center pr-5" @click="clearSearch">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 6.586L12.95 1.636 14.364 3.05 9.414 8l4.95 4.95-1.414 1.414L8 9.414l-4.95 4.95L1.636 12.95 6.586 8 1.636 3.05 3.05 1.636 8 6.586z"
            fill="#999999"
          />
        </svg>
      </button>
    </div>

    <h2
      v-if="route.query?.query && route.name !== 'index'"
      class="lg:text-5xl md:text-4xl text-3xl font-monigue uppercase pt-2"
    >
      Results For <span class="text-zeno-purple">{{ route.query.query }}</span>
    </h2>
  </div>
</template>

<style scoped></style>
